export const userDbData = {
	users: [{
		id:"0",
		fullName: "Martin Perez",
		location: "Vancouver",
		job: "Front End Web Developer",
		aboutMe: "I am amazed at the ever-changing state of front-end web technologies. The upcoming 5 years will see a revolution in the cloud and I expect to deepend into JS to be part of it.",
		githubURL: "https://github.com/martin-webs/",

		linkedinURL: "https://www.linkedin.com/in/martin-web7/",
		favouriteBooks: ["The Lord of the Rings - J. R. R. Tolkien","Foundation Series - Isaac Asimov","Cracking the Coding Interview - Gayle Laakmann","Clean Code - Rob Martin","A Brief History of Time - Stephen Hawking"],
		bookInfo: [{id:"", link:""}],
		HTML: true,
		CSS: true,
		JS: true,
		Git: true,
		React: true,
		NodeJS: true,
		PHP: false,
		SASS: true,
		NPM: true
	}]
}

export const formDataTemplate = {
	id:"",
	fullName: "",
	location: "",
	job: "",
	aboutMe: "",
	githubURL: "",
	linkedinURL: "",
	favouriteBooks: [""],
	bookInfo: [{id:"", link:""}],
	HTML: false,
	CSS: false,
	JS: false,
	Git: false,
	React: false,
	NodeJS: false,
	PHP: false,
	SASS: false,
	NPM: false
}