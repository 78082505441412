import { useNavigate } from "react-location";
import { v4 as uuidv4 } from 'uuid';
import AccessButton from "./AccessButton";

export default function CreateCard({ setFormData, userDb, passUser}) {

  let bookArray = [];
  const navigate = useNavigate();
  const createUserId = () => {
    let userId = uuidv4();
    userId = userId.substring(0,6);
    setFormData((prevState) => { return{...prevState, id: userId} });
  }
  
  const handleChange = (e) => {
    let isChecked = e.target.checked;
    setFormData((prevState) => {
      return {...prevState, [e.target.value]: isChecked }
    });
  };
  
  const submitHandler = (e) => {
    e.preventDefault();
    createUserId();
    
    navigate({ to: "showcase", replace: true });
  }

  const getActiveUser = (au) => {
    passUser(au);
  }

  return (
    <div className="split-screen">
      <div className="left">
        <section className="copy">
          <h1>DevCard</h1>
          <p>Your personal digital portfolio</p>
        </section>
        <AccessButton userDb={userDb} passActiveUser={getActiveUser} />
        <p className="directions">(Enter 0 and click "Access" to see an example)</p>
      </div>
      <div className="right">
        <form onSubmit={submitHandler}>
          <section className="copy">
            <h2 className="create-heading">Create your DevCard</h2>
          </section>
          <div className="lx-row">
            <div className="lx-column">
              <input
                type="text"
                name="fname"
                placeholder="My Name"
                onChange={(e) =>{
                  let val = e.target.value
                  setFormData((prevState) => {return {...prevState, fullName: val }})
                }}
              />
            </div>
            <div className="lx-column">
              <input
                type="text"
                name="location"
                placeholder="Based in"
                onChange={(e) =>{
                  let val = e.target.value
                  setFormData((prevState) => {return {...prevState, location: val }})
                }}
              />
            </div>
          </div>

          <div>
            <input
              type="text"
              name="job"
              placeholder="My Job"
              onChange={(e) =>{
                let val = e.target.value
                setFormData((prevState) => {return {...prevState, job: val }})
              }}
            />
          </div>
          <div>
            <input
              type="text"
              name="aboutMe"
              placeholder="About Me"
              onChange={(e) =>{
                let val = e.target.value
                setFormData((prevState) => {return {...prevState, aboutMe: val }})
              }}
            />
          </div>
          <h2 className="create-heading">Technologies You Know</h2>
          <div className="checkboxes">
            <label className="myLabel">
              <input
                type="checkbox"
                value="HTML"
                onChange={(e) => handleChange(e)}
              />
              HTML
            </label>
            <label className="myLabel">
              <input
                type="checkbox"
                value="CSS"
                onChange={(e) => handleChange(e)}
              />
              CSS
            </label>
            <label className="myLabel">
              <input
                type="checkbox"
                value="JS"
                onChange={(e) => handleChange(e)}
              />
              JS
            </label>
            <label className="myLabel">
              <input
                type="checkbox"
                value="Git"
                onChange={(e) => handleChange(e)}
              />
              Git
            </label>
            <label className="myLabel">
              <input
                type="checkbox"
                value="React"
                onChange={(e) => handleChange(e)}
              />
              React
            </label>
            <label className="myLabel">
              <input
                type="checkbox"
                value="NodeJS"
                onChange={(e) => handleChange(e)}
              />
              Node.JS
            </label>
            <label className="myLabel">
              <input
                type="checkbox"
                value="PHP"
                onChange={(e) => handleChange(e)}
              />
              PHP
            </label>
            <label className="myLabel">
              <input
                type="checkbox"
                value="SASS"
                onChange={(e) => handleChange(e)}
              />
              SASS
            </label>
            <label className="myLabel">
              <input
                type="checkbox"
                value="NPM"
                onChange={(e) => handleChange(e)}
              />
              NPM
            </label>
          </div>
          <div>
            <input
              type="text"
              name="githubURL"
              placeholder="Github URL: https://"
              onChange={(e) =>
                setFormData((prevState) => {return { ...prevState, githubURL: e.target.value }})}
            />
          </div>
          <div>
            <input
              type="text"
              name="linkedinURL"
              placeholder="linkedin URL: https://"
              onChange={(e) =>
                setFormData((prevState) => {return { ...prevState, linkedinURL: e.target.value }})}
            />
          </div>
          <div>
            <input
              type="text"
              name="favouriteBooks"
              placeholder="Favourite Books (separated by comma)"
              onChange={(e) => {
                let booksString = e.target.value.toLowerCase();
                bookArray = booksString.split(',');
                setFormData((prevState) => {return { ...prevState, favouriteBooks: bookArray }});
              }}
            />
          </div>
          <div className="btn-wrapper">
            <button className="signup-btn" type="submit">
              Create Site
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
