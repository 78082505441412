import React from 'react';
import CardShowcase from './CardShowcase';
import "./showcase.css";

export default function DisplayUser({ userDb, displayActiveUser } ) {

	const activeUserDb = userDb.users[displayActiveUser];

	const controllingFunction = () => {
		console.log('Displaying user.')
	}

	return (
		<div>
			<CardShowcase formData={activeUserDb} onSaveUserData={controllingFunction} />
		</div>
	)
}
