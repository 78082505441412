import React, {useState} from 'react';
import { useNavigate } from 'react-location';

export default function AccessCard({userDb, passActiveUser}) {

	const navigate = useNavigate();
	const[userNumber, setUserNumber] = useState(0);
	const activeUserHandler = (event) => {
		let userN = event.target.value;
		setUserNumber(userN);
	}
	const accessButtonHandler = () => {
		navigate({to:"user/"+userNumber, replace: true});
		passActiveUser(userNumber);
	}

	return (
			<div id="user-management">
					<input 
						type="text"
						placeholder="User #" 
						onChange={activeUserHandler} 
					/>
					{userNumber  < userDb.users.length 
					? <button type="submit" onClick={accessButtonHandler}>Access</button>  
					: <p id="nf-message">User not found.</p>}
			</div>
	)
}