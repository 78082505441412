import { useState } from "react";
import { Outlet, ReactLocation, Router } from "react-location";
import CardShowcase from "./component/CardShowcase";
import CreateCard from "./component/CreateCard";
import DisplayUser from "./component/DisplayUser";
import { formDataTemplate, userDbData } from "./data";
import "./styles.css";

const location = new ReactLocation();

export default function App() {
  const [activeUser, setActiveUser] = useState(0);
  const [formData, setFormData] = useState(formDataTemplate);
  const [userDb, setUserDb] = useState(userDbData);

  const createUser = (userData) => {
    setUserDb((prevState) => {
      return {
        ...prevState,
        users: [...prevState.users.concat(userData)],
      };
    });
  };

  const passUser = (au) => {
    setActiveUser(au);
  };

  const routes = [
    {
      path: "/",
      element: (
        <CreateCard
          formData={formData}
          setFormData={setFormData}
          userDb={userDb}
          passUser={passUser}
        />
      ),
    },
    {
      path: "showcase",
      element: (
        <CardShowcase
          formData={formData}
          setFormData={setFormData}
          onSaveUserData={createUser}
        />
      ),
    },
    {
      path: "user",
      element: (<DisplayUser userDb={userDb} displayActiveUser={activeUser} />),
    },
  ];

  return (
    <Router routes={routes} location={location}>
      <div>
        <Outlet />
      </div>
    </Router>
  );
}
