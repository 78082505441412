import { useEffect,  } from "react";
import { useNavigate } from "react-location";
import "./showcase.css";

export default function CardShowcase({ formData, onSaveUserData, setFormData }) {

  const navigate = useNavigate();

  useEffect(() => {
    onSaveUserData(formData);
  },[onSaveUserData,formData]);

  function newHandleClick(e){
    e.preventDefault()
    navigate({to: "/", replace : true})
  }


  return (
    <div className="pageBody">
      <main className="has-dflex-center">
        <section>
          <div className="lx-container-85">
            <div className="lx-row align-stretch">
              <h1 id="hidden-title">Welcome to {formData.fullName}'s Web Dev Page</h1>
              <div className="lx-column is-3">
                <div className="lx-row">
                  <div className="lx-card">
                    <div className="lx-row">
                      <div className="has-dflex-center bs-md pic">
                        <img
                          src="https://github.com/martin-webs.png"
                          alt="Armaan"
                        />
                      </div>
                      <div className="infos">
                        <span className= "cap">
                          User Id: &nbsp;&nbsp;{formData.id}
                        </span>
                        <span className= "cap">
                          <i className="fas fa-user-circle"></i>&nbsp;&nbsp;{formData.fullName}
                        </span>
                        <span className="cap">
                          <i className="fas fa-briefcase"></i>&nbsp;&nbsp;{formData.job}
                        </span>
                        <span className="cap">
                          <i className="fas fa-map-marker-alt"></i>&nbsp;&nbsp;{formData.location}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="lx-card">
                    <div className="lx-row">
                      <h2 className="title cap">
                        Connect with {formData.fullName}
                      </h2>
                      <div className="mini-cards">
                        <a
                          className="has-dflex-center bs-md"
                          href={formData.githubURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-github-alt"></i>
                        </a>
                        <a
                          className="has-dflex-center bs-md"
                          href={formData.linkedinURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lx-column">
                <div className="lx-row">
                  <div className="lx-row lx-card">
                    <h2 className="title cap">
                      <i className="fas fa-info-circle"></i>&nbsp;Welcome to &nbsp;
                      {formData.fullName}'s corner of the Internet
                    </h2>
                  </div>
                  <div className="lx-row lx-card">
                    <h2 className="title">
                      <i className="fas fa-hand-point-right"></i>&nbsp;About me
                    </h2>
                    <div className="text">
                      <p>
                       {formData.aboutMe}
                      </p>
                    </div>
                  </div>
                  <div className="lx-row lx-card">
                    <h2 className="title">
                      <i className="fas fa-terminal"></i>&nbsp;Technologies
                    </h2>
                    <div className="mini-cards">
                     {formData.HTML && 
                      <span className="has-dflex-center bs-md" title="HTML">
                          <i className="fab fa-html5"></i>
                        </span> }
                     {formData.CSS &&
                      <span className="has-dflex-center bs-md" title="CSS">
                        <i className="fab fa-css3-alt"></i>
                      </span> }
                     {formData.JS &&
                      <span className="has-dflex-center bs-md" title="JS">
                        <i className="fab fa-js"></i>
                      </span> }
                     {formData.Git &&
                      <span className="has-dflex-center bs-md" title="Git">
                        <i className="fab fa-git-alt"></i>
                      </span> }
                     {formData.React &&
                      <span className="has-dflex-center bs-md" title="React">
                        <i className="fab fa-react"></i>
                      </span> }
                     {formData.NodeJS &&                 
                      <span className="has-dflex-center bs-md" title="Node JS">
                        <i className="fab fa-node-js"></i>
                      </span> }
                     {formData.PHP &&
                      <span className="has-dflex-center bs-md" title="PHP">
                        <i className="fab fa-php"></i>
                      </span> }
                     {formData.SASS &&
                      <span className="has-dflex-center bs-md" title="SASS">
                        <i className="fab fa-sass"></i>
                      </span> }
                     {formData.NPM &&                 
                      <span className="has-dflex-center bs-md" title="NPM">
                        <i className="fab fa-npm"></i>
                      </span> }
                    </div>
                  </div>
                  <div id="book-list" className="lx-row lx-card">
                    <h2 className="title">
                      <i className="fas fa-book"></i>&nbsp;My favorite books
                    </h2>
                    <ol id="fav-book-list">
                      {formData.favouriteBooks.map((b, index) => {
                        return(
                            <li className="cap"  key={index}>{b}</li>
                          )})} 
                    </ol>
                    <div className="goBack-btn-wrapper">
                      <button id="goBack-btn"onClick={newHandleClick} >Go Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
